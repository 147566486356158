<template>
  <section>
    <v-tabs
      v-model="tabs"
      background-color="grey lighten-4"
      slider-size="0"
      class="rounded-lg"
      grow
      :show-arrows="true"
    >
      <v-tab active-class="primary white--text rounded-lg">
        <v-badge inline color="primary lighten-1" :content="postsCount" :value="postsCount">
          <span class="f11">{{ $t('Posts') }}</span>
        </v-badge>
      </v-tab>
      <v-tab active-class="primary white--text rounded-lg">
        <v-badge inline color="primary lighten-1" :content="videosCount" :value="videosCount">
          <span class="f11">{{ $t('Videos') }}</span>
        </v-badge>
      </v-tab>
      <v-tab active-class="primary white--text rounded-lg">
        <v-badge inline color="primary lighten-1" :content="podcastsCount" :value="podcastsCount">
          <span class="f11">{{ $t('Podcasts') }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs" class="py-2">
      <!-------------------Posts--------------------------------------->
      <v-tab-item>
        <v-row v-if="postsCount > 0">
          <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(item, index) in posts" :key="index">
            <v-card :to="`/c/contents/post/${item.id}`" class="rounded-lg">
              <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center"></v-img>

              <v-card-title class="f13 single-line pb-0">
                {{ item.title }}
              </v-card-title>

              <v-card-actions @click.prevent="openDrBottomSheet(item.publisher)" class="pt-0">
                <v-list-item two-line class="p-0">
                  <v-list-item-avatar>
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.publisherPic"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="f12 font-weight-bold primary--text">{{ item.publisherName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="f10">{{ item.createDate | moment("from", "now") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <span class="text-center f16 d-block mt-8" v-else>
          {{$t('No results found')}}
        </span>
      </v-tab-item>

      <!-------------------Videos-------------------------------------->
      <v-tab-item>
        <v-row v-if="videosCount > 0">
          <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(item, index) in videos" :key="index">
            <v-card :to="`/c/contents/video/${item.id}`" class="rounded-lg">
              <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center">
                <div class="text-white w-100 text-center">
                  <i class="fas fa-play p-4 bg-black-50 rounded-pill"></i>
                </div>
              </v-img>

              <v-card-title class="f13 single-line pb-0">
                {{ item.title }}
              </v-card-title>

              <v-card-actions @click.prevent="openDrBottomSheet(item.publisher)" class="pt-0">
                <v-list-item two-line class="p-0">
                  <v-list-item-avatar>
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.publisherPic"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="f12 font-weight-bold primary--text">{{ item.publisherName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="f10">{{ item.createDate | moment("from", "now") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <span class="text-center f16 d-block mt-8" v-else>
          {{$t('No results found')}}
        </span>
      </v-tab-item>
      
      
      <!-------------------Podcasts-------------------------------------->
      <v-tab-item>
        <v-row v-if="podcastsCount > 0">
          <v-col xs="6" sm="6" md="4" lg="3" xl="3" cols="6" v-for="(item, index) in podcasts" :key="index">
            <v-card :to="`/c/contents/podcast/${item.id}`" class="rounded-lg">
              <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="item.picture" :aspect-ratio="1 / 1" class="d-flex align-items-center justify-content-center">
                <div class="text-white w-100 text-center">
                  <i class="fas fa-music p-4 bg-black-50 rounded-pill"></i>
                </div>
              </v-img>

              <v-card-title class="f13 single-line pb-0">
                {{ item.title }}
              </v-card-title>

              <v-card-actions @click.prevent="openDrBottomSheet(item.publisher)" class="pt-0">
                <v-list-item two-line class="p-0">
                  <v-list-item-avatar>
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="item.publisherPic"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="f12 font-weight-bold primary--text">{{ item.publisherName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="f10">{{ item.createDate | moment("from", "now") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <span class="text-center f16 d-block mt-8" v-else>
          {{$t('No results found')}}
        </span>
      </v-tab-item>
    </v-tabs-items>

    <BshDoctor ref="drBottomSheet"/>
  </section>
</template>

<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      tabs: null,

      postsCount: 0,
      videosCount: 0,
      podcastsCount: 0,

      posts: [],
      videos: [],
      podcasts: [],
    }
  },
  methods: {
    openDrBottomSheet(mobile){
      this.$refs.drBottomSheet.openWithDoctorMobile(mobile);
    },
    getBookmarkedPost(){
      apiService.getBookmarkedPost()
      .then((response) => {
        this.postsCount = response.data.length;
        this.posts = response.data;
      })
    },
    getBookmarkedVideo(){
      apiService.getBookmarkedVideo()
      .then((response) => {
        this.videosCount = response.data.length;
        this.videos = response.data;
      })
    },
    getBookmarkedPodcast(){
      apiService.getBookmarkedPodcast()
      .then((response) => {
        this.podcastsCount = response.data.length;
        this.podcasts = response.data;
      })
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted(){
    this.getBookmarkedPost();
    this.getBookmarkedVideo();
    this.getBookmarkedPodcast();
  },
}
</script>
